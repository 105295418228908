import { render, staticRenderFns } from "./videoStreaming.vue?vue&type=template&id=6565627e&scoped=true"
import script from "./videoStreaming.vue?vue&type=script&lang=js"
export * from "./videoStreaming.vue?vue&type=script&lang=js"
import style0 from "./videoStreaming.vue?vue&type=style&index=0&id=6565627e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6565627e",
  null
  
)

export default component.exports