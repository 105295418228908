<template>
  <div class="container">
    <div class="title">获取摄像头视频流</div>
    <div class="content main" v-for="(item,index) in info" :key="item.title">
      <template v-if="!item.tableData">
        <div class="listTitle">{{ item.title }}</div>
        <div :style="{color: index == 1 ? '#0077FF' : '',lineHeight: '1.5',whiteSpace: 'pre-line',wordBreak:'break-all'}">{{ item.content }}</div>
      </template>
      <template v-else>
        <div class="listTitle">
          {{ item.title }}
        </div>
        <div class="table">
          <el-table
            :data="item.tableData"
            :header-cell-style="{
              backgroundColor: '#0077FF',
              color: '#FFF',
              fontWeight: '500',
            }"
          >
            <el-table-column
              v-for="(column, index) in item.tableKey"
              :key="column"
              :prop="column"
              :label="item.tableName[index]"
            >
            </el-table-column>
            <!-- <el-table-column prop="tips" label="提示"> </el-table-column> -->
          </el-table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "OpenWebVideoStreaming",

  data() {
    return {
      info: [
        {
          title: "1、接口说明",
          content: "通过该接口获取查看视频流画面。",
        },
        {
          title: "2、接口地址",
          content:
            // "https://lssj.allynav.cn/live/livestream200T008392400042.flv?t=194ea2f6dea618487e8e6395676554f9b81e6b4e7283b611492487ff461b2548&expire=1717751510",
            // "https://lssj.allynav.cn/live/livestream｛sn｝.flv?t={t}&expire={expire}",
            "https://{域名}/api/v1/dev/getAddress"
        },
        {
          title: "3、认证方式",
          content: "token",
        },
        {
          title: "4、请求头",
          content: "",
          tableData: [
            {
              name: "Authorization",
              type: "string",
              require: "Y",
              example: "Bearer token值",
              describe: "Token认证",
            },
          ],
          tableKey: ["name", "type", "require", "example", "describe"],
          tableName: ["名称", "类型", "是否必填", "示例值", "描述"],
        },
        {
          title: "4、请求类型",
          content: "post",
        },
        {
          title: "5、直播地址示例",
          content: "可以通过{_push#channel}拼接通道号，查看摄像头不同的通道信息。\nhttps://lssj.allynav.cn/live/livestream200T008392400042{_push#channel}.flv?t=009ae4e1b4df7a8565f02ed6cde1b5a862ba7275905a7a70dd35a822d468a850&expire=1722927436",
        },
        {
          title: "6、请求参数",
          content: "",
          tableData: [
            {
              name: "sn",
              type: "string",
              require: "Y",
              example: "",
              describe: "摄像头sn",
            }
          ],
          tableKey: ["name", "type", "require", "example", "describe"],
          tableName: ["名称", "类型", "是否必填", "示例值", "描述"],
        },
        {
          title: "7、响应参数",
          content: "",
          tableData: [
            {
              name: "code",
              type: "string",
              require: "Y",
              example: "",
              describe: "返回状态码",
            },
            {
              name: "msg",
              type: "string",
              require: "Y",
              example: "成功",
              describe: "返回状态消息",
            },
            {
              name: "data",
              type: "object",
              require: "",
              example: "",
              describe: "",
            },
          ],
          tableKey: ["name", "type", "require", "example", "describe"],
          tableName: ["名称", "类型", "是否必填", "示例值", "描述"],
        },
        {
          title: "8、data",
          content: "",
          tableData: [
            {
              name: "addressList",
              type: "Array",
              require: "N",
              example: "",
              describe: "地址列表",
            }
          ],
          tableKey: ["name", "type", "require", "example", "describe"],
          tableName: ["名称", "类型", "是否必填", "示例值", "描述"],
        },
        {
          title: "9、addressList",
          content: "",
          tableData: [
            {
              name: "address",
              type: "string",
              require: "N",
              example: "",
              describe: "直播地址",
            },{
              name: "expireTime",
              type: "string",
              require: "N",
              example: "",
              describe: "有效时间",
            }
          ],
          tableKey: ["name", "type", "require", "example", "describe"],
          tableName: ["名称", "类型", "是否必填", "示例值", "描述"],
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.title {
  padding: 10px 0;
  font-size: 24px;
  font-weight: 500;
}
.listTitle {
  font-weight: 500;
}
.main > div {
  margin: 20px 0;
  // background-color: red;
}
.table {
  width: 75%;
}
.main:last-child {
  padding-bottom: 100px;
}
</style>