<template>
  <custom-dialog
    :show="show"
    :title="'编辑账号'"
    @toggleShow="handleClose"
    width="400"
  >
    <div class="flex form" slot="main">
      <el-form
        :rules="rules"
        ref="form"
        :model="form"
        label-width="100px"
        label-position="left"
      >
        <el-form-item
          :label="$t(item.label) + '：'"
          v-for="item in currentFormItem"
          :key="item.key"
          :prop="item.key"
        >
          <template v-if="item.inputType == 'select'">
            <el-select
              v-model="form[item.key]"
              style="width: 250px"
              size="small"
              :placeholder="$t(item.label)"
            >
              <el-option
                v-for="option in $t(item.selectKey)"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              >
              </el-option>
            </el-select>
          </template>
          <template v-else-if="item.inputType == 'input'">
            <template v-if="item.key == 'elevation'">
              <div class="flex">
                <el-input
                  v-model="form[item.key]"
                  :placeholder="$t(item.label)"
                ></el-input>
                <span style="margin: 0 12px">m</span>
                <el-button type="primary" class="btn save" size="small">
                  {{ $t("label.model.getLonlat") }}
                </el-button>
              </div>
            </template>
            <template v-else>
              <el-input
                size="small"
                style="width: 250px"
                v-model="form[item.key]"
                :placeholder="$t(item.label)"
              ></el-input>
            </template>
          </template>
        </el-form-item>
      </el-form>
    </div>

    <div class="flex btns" slot="footer">
      <el-button
        type="primary"
        class="btn"
        @click="handleSubmit"
        size="small"
      >
        {{ $t("public.save") }}
      </el-button>
      <el-button
        class="btn"
        size="small"
        @click="handleClose()"
      >
        {{ $t("public.cancel") }}
      </el-button>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../../components/public/customDialog";
import { updateProjectName } from "@/api/index";
export default {
  name: "ConfimePwd",
  components: {
    CustomDialog,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rowInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      formItem: [
        {
          label: "public.projectName",
          key: "projectName",
          inputType: "input",
        },
      ],
      form: {
        projectName: "",
      },
      rules: {
        projectName: [
          {
            required: true,
            message: "请输入项目名称",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    currentFormItem() {
      // if (this.rowInfo && this.rowInfo.id) {
      // 	return this.formItem.filter(
      // 		(f) => f.key == "accountType" || f.key == "contact"
      // 	);
      // } else {
      // 	return this.formItem;
      // }
      return this.formItem;
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.form = { ...this.rowInfo };
          console.log(this.rowInfo, "vvvv");
        }
      },
      deep: true,
    },
  },
  methods: {
    handleClose(done) {
      // this.$emit("toggleShow", false);
      this.$emit("update:show", false);
    },
    handleSubmit() {
      //   this.$emit("submit");
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // alert('submit!');
          let params = {
            projectId: this.rowInfo.projectId,
            projectName: this.form.projectName,
          }
          updateProjectName(params).then(res=>{
            if(res.code == 200){
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit("refush");
            }
          })
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  justify-content: center;
  .btn {
    width: 180px;
    height: 32px;
  }
}
.form {
  justify-content: center;
}
</style>